<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="botaoDepoisAcaoTitulo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="v-btn--simple"
            icon
            v-bind="attrs"
            @click="mostraFiltro = !mostraFiltro"
            v-on="on"
          >
            <v-icon>{{ mostraFiltro? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro') }}</span>
      </v-tooltip>
    </template>
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="hidden-sm-and-down justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.nome }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td
          class="hidden-sm-and-down justify-left"
        >
          {{ item.item.caixa }}
        </td>
        <td
          class="hidden-sm-and-down"
        >
          <sim-nao-icon
            :ativo="item.item.permiteParcelamento"
            :small="false"
          />
        </td>
        <td
          class="hidden-md-and-up justify-center"
          @click="editar(item.item)"
        >
          <div class="nome">
            <v-icon small>
              mdi-pencil
            </v-icon>
            {{ item.item.nome }}
          </div>
        </td>
        <td
          class="hidden-md-and-up justify-center"
        >
          <div class="valor">
            <v-icon small>
              mdi-database-search
            </v-icon>
            <span v-money="{simbolo:'R$', valor: item.item.valor}" />
          </div>
          <div class="caixa">
            <v-icon>
              mdi-cash-register
            </v-icon>
            {{ item.item.caixa }}
          </div>
          <div class="permiteParcelamento">
            <sim-nao-icon
              :ativo="item.item.permiteParcelamento"
              :small="false"
            />
          </div>
        </td>
        <td v-if="!idEmpresaCliente || idEmpresaCliente < 0">
          <span v-if="item.item.EmpresaCliente !== null">
            {{ item.item.EmpresaCliente.nome }}
          </span>
        </td>
      </tr>
    </template>

    <template slot="antesDaDataTable">
      <v-row v-show="mostraFiltro">
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <caixa-select
            v-model="filtro.idCaixa"
            :readonly="!empresaClienteModelId"
            :id-empresa-cliente="empresaClienteModelId || idEmpresaCliente"
            adiciona-valor-todos
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-select
            v-model="filtro.finalidade"
            :label="$t('FormaPagamentoForm.finalidade')"
            :items="finalidades"
            item-value="id"
            item-text="nome"
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>
      </v-row>
      <v-row v-show="mostraFiltro">
        <v-col
          cols="1"
          sm="3"
        >
          <v-btn
            class="v-btn--simple"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.smAndDown"
            @click="buscar"
          >
            <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <hr v-show="mostraFiltro">
    </template>
  </crud-list>
</template>

<script>

  import Servico from '../services/FormaPagamentoService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'
  import DateUtils from "../utils/DateUtils"
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete"
  import EmpresaClientesService from '../services/EmpresaClientesService'
  import CaixaSelect from '../components/CaixaSelect.vue'
  import SimNaoIcon from '../components/SimNaoIcon'

  export default {
    components: {
      EmpresaClienteAutoComplete,
      CaixaSelect,
      SimNaoIcon
    },
    data: () => ({
    
      totalRegistros: 0,
      pagination: {},
      items: [],
      // headers: [],
      loading: false,
      itemRemover: {},
      mostraFiltro: true,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      caixaModelId: null,
      autoCompleteCaixaModel: null,
      filtro: {
        idEmpresaCliente: null,
        idCaixa: null,
        finalidade: null
      },
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('FormaPagamentoForm.tituloPesquisa')
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('FormaPagamentoForm.nome'),
            value: 'nome',
            sortable: false
          },
          {
            text: this.$i18n.t('FormaPagamentoForm.caixa'),
            value: 'caixa',
            sortable: false
          },
          {
            text: this.$i18n.t('FormaPagamentoForm.permiteParcelamento'),
            class: 'text-center',
            value: 'permiteParcelamento',
            sortable: false
          },
        ]

        if (!this.idEmpresaCliente || this.idEmpresaCliente < 0) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      },
      finalidades() {
        const retorno = [
          {
            id: null,
            nome: this.$i18n.t('padrao_todos')
          },
          {
            id: 1,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_1')
          },
          {
            id: 2,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_2')
          },
          {
            id: 3,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_3')
          }
        ]
        return retorno
      }
    },
    watch: {
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.empresaClienteModelId = null
          this.filtro.idEmpresaCliente = null
          this.autoCompleteCaixaModel= null
        } else {
          this.empresaClienteModelId = val.id
          this.filtro.idEmpresaCliente = val.id
        }
      },
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)
      this.$root._events.pesquisa = []
      // this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    mounted() {

      if(localStorage.getItem('pagination-formapagamento-list')){
        this.pagination = JSON.parse(localStorage.getItem('pagination-formapagamento-list'))
      }

      if(localStorage.getItem('filtro-formapagamento-list') != null){
        this.filtro = JSON.parse(localStorage.getItem('filtro-formapagamento-list'))
        if(this.filtro.idEmpresaCliente && this.filtro.idEmpresaCliente > 0){
          EmpresaClientesService.findById(this.filtro.idEmpresaCliente)
            .then((res) => {
              this.empresaClienteModel = res.data
              this.empresaClienteModelId = res.data.id
              this.started = true
              this.buscar()
            })
        }else{
          this.started = true
          this.buscar()
        }

      }else{
        this.started = true
        this.buscar()
      }

    },
    methods: {

      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true
        this.filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente

        const str = JSON.stringify(this.filtro)
        localStorage.setItem('filtro-formapagamento-list', str)
        localStorage.setItem('pagination-formapagamento-list', JSON.stringify(this.pagination))

        Servico.findByParam(this.filtro, this.pagination)
          .then((retorno) => {
            if (retorno && retorno.data && retorno.data.rows) {
              this.items = retorno.data.rows
            }
            if (retorno && retorno.data) {
              this.totalRegistros = retorno.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      parseDate(date) {
        return DateUtils.parseDate(date);
      },
      novo () {
        this.$router.push({
          path: '/formaPagamentoForm'
        })
      },
      editar (registro) {
        if (registro && registro.id) {
          const url = '/formaPagamentoForm/' + registro.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
</style>
